<template>
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding forex" v-if="!$apollo.queries.majors.loading">
    <div class="block-wrapper-top">
      <div class="block-left"></div>
      <div class="block-right"></div>
      <div class="block-center"></div>
    </div>
    <div class="block-wrapper-left">
      <div class="block-wrapper-right">
        <div class="block-wrapper-center">
          <div class="block-wrapper-content">
            <div class="gvrl-table">
              <div class="gvrl-table-caption">
                Форекс
              </div>
              <div class="gvrl-table-header">
                <div class="gvrl-table-row">
                  <div class="gvrl-table-cell">
                    Валютна пара
                  </div>
                  <div class="gvrl-table-cell">
                    Bid
                  </div>
                  <div class="gvrl-table-cell">
                    Ask
                  </div>
                </div>
              </div>
              <div class="gvrl-table-body">
                <ForexRow v-for="major in majors" :key="major.id" :major="major" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-wrapper-bottom">
      <div class="block-left"></div>
      <div class="block-right"></div>
      <div class="block-center"></div>
    </div>
  </div>
</template>

<script>
import ForexRow from "@/components/ForexRow";

export default {
  name: 'ForexTable',
  components: {
    ForexRow
  },
  data() {
    return {
      majors: []
    }
  },
  apollo: {
    majors: {
      query: require('../graphql/ForexMajors.graphql'),
      update: data => data.forexMajors,
      subscribeToMore: {
        document: require('../graphql/ForexSubscription.graphql'),
        updateQuery: ((previousQueryResult, {subscriptionData}) => {
          if (!subscriptionData.data) return previousQueryResult;
          const updates = subscriptionData.data.subscribeForex;
          const updatesById = Object.assign({}, ...updates.map(s => ({[s.id]: s})));
          previousQueryResult.forexMajors.map(prev => {
            const updateById = updatesById[prev.id]
            if (!updateById) return prev;
            if (updateById.bid) prev.bid.close = updateById.bid.close;
            if (updateById.ask) prev.ask.close = updateById.ask.close;
            return prev
          });
          return previousQueryResult
        })
      }
    }
  }
}
</script>

<style>
.forex .block-wrapper-content {
  background-color: #000;
}
.forex .gvrl-table {
  width: 100%;
  border-spacing: 3px;
  border-collapse: separate;
  text-align: center;
  line-height: normal;
}
.forex .gvrl-table-caption {
  background-color: #333;
  border: 3px solid #000;
  border-bottom: none;
  font-size: 18pt;
  font-weight: bold;
}
.forex .gvrl-table-body {
  font-size: 14pt;
}
.forex .gvrl-table-body .gvrl-table-row, .forex .gvrl-table-header .gvrl-table-row, .forex .gvrl-table-footer .gvrl-table-row {
  background-color: #333;
}
.forex .gvrl-table-body .gvrl-table-row {
  height: 30px;
  line-height: 30px;
}
.forex .gvrl-table-cell {
  width: 33%;
}
.forex .gvrl-table-footer .gvrl-table-row {
  width: 100%;
}
</style>
