<template>
  <div class="col-xs-12 no-padding hidden-xs">
    <div id="welcome">
      <div class="block-wrapper-top">
        <div class="block-left"></div>
        <div class="block-right"></div>
        <div class="block-center"></div>
      </div>
      <div class="block-wrapper-left">
        <div class="block-wrapper-right">
          <div class="block-wrapper-center">
            <div class="block-wrapper-content">
              <h1>Будь в курсі! Інформаційні послуги з обміну валют, фінансові консультації, надійність та конфіденційність.</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="block-wrapper-bottom">
        <div class="block-left"></div>
        <div class="block-right"></div>
        <div class="block-center"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Welcome"
}
</script>

<style scoped>
#welcome .block-wrapper-content {

  background-color: #333;
  border: 3px solid #000;
  padding: 3px;

  line-height: normal;
  text-align: center;
}
#welcome h1 {
  font-family: inherit;
  font-weight: bold;
  line-height: normal;
  font-size: 18pt;
  margin: 0;
}
</style>