import Vue from 'vue'
import App from './App.vue'
import apolloProvider from './vue-apollo'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, {config: { id: "G-3YRF0X9QKM" }});

new Vue({
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
