<template>
  <div class="gvrl-table-cell">{{base.codeAlpha}}/{{quote.codeAlpha}}</div>
</template>

<script>
export default {
  name: "ForexCurrencyPair",
  props: [
      "base",
      "quote"
  ]
}
</script>

<style scoped>

</style>