<template>
  <div v-bind:class="itemClass" v-on:animationend="this.reset">{{close}}</div>
</template>

<script>
export default {
  name: "ForexCell",
  props: [
    "value",
    "precision",
  ],
  data: function() {
    return  {
      itemClass: ['gvrl-table-cell']
    }
  },
  computed: {
    close: function () {
      return this.value.close.toFixed(this.precision);
    }
  },
  methods: {
    reset: function() {
      this.itemClass = ['gvrl-table-cell']
    },
    up: function () {
      this.itemClass.push('up');
    },
    down: function () {
      this.itemClass.push('down');
    }
  },
  watch: {
    close: {
      handler: function (val, oldVal) {
        val = parseFloat(val);
        oldVal = parseFloat(oldVal);
        if (val && oldVal) {
          const delta = parseFloat((val - oldVal).toFixed(this.precision));
          if (delta > 0) {
            this.up()
          } else if (delta < 0) {
            this.down()
          }
        }
        return val
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.forex__value__absolute {
  font-weight: 500;
  font-size: 1.2em;
}

@keyframes upAnimation {
  from {background-color: #00ff00;}
  to {background-color: inherit;}
}

@keyframes downAnimation {
  from {background-color: #ff0000;}
  to {background-color: inherit;}
}

.up {
  background-color: inherit;
  animation-name: upAnimation;
  animation-duration: 2s;
}

.down {
  background-color: inherit;
  animation-name: downAnimation;
  animation-duration: 2s;
}
</style>