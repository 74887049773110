<template>
  <nav class="navbar">
    <div class="container">
      <div class="navbar-header">
        <a class="navbar-brand"><img class="img-responsive" src="@/assets/logo.png" alt="" /></a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar"
}
</script>

<style scoped>
.navbar {
  background-color: #000000;
  border: none;
  border-bottom: 3px solid #333333;
  border-radius: 0;
  height: 60px;
}

.navbar-brand {
  padding: 0;
}
</style>