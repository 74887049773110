<template>
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div class="row">
      <div class="col-xs-12 no-padding rates" v-if="!$apollo.queries.point.loading">
        <div class="block-wrapper-top">
          <div class="block-left"></div>
          <div class="block-right"></div>
          <div class="block-center"></div>
        </div>
        <div class="block-wrapper-left">
          <div class="block-wrapper-right">
            <div class="block-wrapper-center">
              <div class="block-wrapper-content">
                <div class="gvrl-table">
                  <div class="gvrl-table-caption">
                    Курс на {{ updated_at }}
                    <p style="font-size: 18px; font-weight: normal;">
                      Телефон: <a href="tel:+380503397777" style="color: #cccd80;">(050) 339-77-77</a><br>
                      Telegram: <a href="https://t.me/goverla_ua" style="color: #cccd80;">@goverla_ua</a>
                    </p>
                  </div>
                  <div class="gvrl-table-header">
                    <div class="gvrl-table-row">
                      <div class="gvrl-table-cell">
                        Валюта
                      </div>
                      <div class="gvrl-table-cell">
                        Купівля
                      </div>
                      <div class="gvrl-table-cell">
                        Продаж
                      </div>
                    </div>
                  </div>
                  <div class="gvrl-table-body">
                    <RatesRow v-for="rate in point.rates" v-bind:key="rate.id" v-bind:rate="rate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block-wrapper-bottom">
          <div class="block-left"></div>
          <div class="block-right"></div>
          <div class="block-center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatesRow from "@/components/RatesRow";

export default {
  name: "RatesTable",
  components: {
    RatesRow
  },
  data() {
    return {
      point: {
        rates: []
      }
    }
  },
  computed: {
    updated_at: function () {
      if (!this.point.updatedAt) return "";
      let dt = new Date(this.point.updatedAt);
      return [dt.getDate(), dt.getMonth()+1, (dt.getFullYear() + '').slice(2, 4)]
          .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
    }
  },
  apollo: {
    point: {
      query: require('../graphql/Point.graphql'),
      variables: {alias: 'goverla-ua'},
      update: data => data.point,
      subscribeToMore: {
        document: require('../graphql/RatesSubscription.graphql'),
        updateQuery: ((previousQueryResult, {subscriptionData}) => {
          if (!subscriptionData.data) return previousQueryResult;
          const updates = subscriptionData.data.subscribeRates;
          const updatesById = Object.assign({}, ...updates.map(s => ({[s.id]: s})));
          previousQueryResult.point.rates.map(prev => {
            const updateById = updatesById[prev.id]
            if (!updateById) return prev;
            if (updateById.bid) prev.bid = updateById.bid;
            if (updateById.ask) prev.ask = updateById.ask;
            return prev
          });
          return previousQueryResult
        })
      }
    }
  }
}
</script>

<style>
.rates {
  min-width: 300px;
}
.rates .block-wrapper-content {
  background-color: #000;
}
.rates .gvrl-table {
  width: 100%;
  border-spacing: 3px;
  border-collapse: separate;
  text-align: center;
  line-height: normal;
}
.rates .gvrl-table-caption {
  background-color: #333;
  border: 3px solid #000;
  border-bottom: none;
}
.rates .gvrl-table-caption, .rates .gvrl-table-body {
  font-size: 18pt;
  font-weight: bold;
}
.rates .gvrl-table-body .gvrl-table-row, .rates .gvrl-table-header .gvrl-table-row {
  background-color: #333;
}
.rates .gvrl-table-body .gvrl-table-row {
  height: 60px;
  line-height: 60px;
}
.rates img {
  display: block;
  width: 88px;
  height: 60px;
}
.rates .gvrl-table-cell:first-of-type {
  width: 88px;
}
.rates .gvrl-table-cell:not(:first-of-type) {
  width: 50%;
  vertical-align: middle;
}
</style>
