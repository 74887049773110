<template>
  <div>
    <Navbar/>
    <div class="container">
      <div class="row">
        <Welcome/>
        <RatesTable/>
        <Twitter/>
        <ForexTable/>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Welcome from "@/components/Welcome";
import RatesTable from "@/components/RatesTable";
import Twitter from "@/components/Twitter";
import ForexTable from "@/components/ForexTable";
export default {
  name: 'App',
  components: {
    ForexTable,
    Twitter,
    Navbar,
    Welcome,
    RatesTable,
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.css';
body {
  color: #cccd80;
  font-family: Verdana,sans-serif;
  font-size: 11pt;
  text-shadow: 2px 2px #000;
  cursor: default;
  background-color: #000000;
}
.no-padding {
  padding: 0 !important;
}
.block-wrapper-top, .block-wrapper-bottom {
  height: 20px;
}
.block-left, .block-right {
  width: 20px;
  height: 100%;
}
.block-left {
  float: left;
}
.block-right {
  float: right;
}
.block-center {
  height: 100%;
  overflow: hidden;
}
.block-wrapper-center {
  margin: 0 20px;
}
.block-wrapper-left {
  background-image: url(assets/block/bl.png);
  background-position: left;
  background-repeat: repeat-y;
}
.block-wrapper-right {
  background-image: url(assets/block/br.png);
  background-position: right;
  background-repeat: repeat-y;
}
.block-wrapper-top .block-left {
  background-image: url(assets/block/blt.png);
  background-repeat: no-repeat;
}
.block-wrapper-top .block-right {
  background-image: url(assets/block/brt.png);
  background-repeat: no-repeat;
}
.block-wrapper-top .block-center {
  background-image: url(assets/block/bt.png);
  background-repeat: repeat-x;
}
.block-wrapper-bottom .block-left {
  background-image: url(assets/block/blb.png);
  background-repeat: no-repeat;
}
.block-wrapper-bottom .block-right {
  background-image: url(assets/block/brb.png);
  background-repeat: no-repeat;
}
.block-wrapper-bottom .block-center {
  background-image: url(assets/block/bb.png);
  background-repeat: repeat-x;
}
.gvrl-table {
  display: table;
}
.gvrl-table-caption {
  display: table-caption;
}
.gvrl-table-header {
  display: table-header-group;
}
.gvrl-table-footer {
  display: table-footer-group;
}
.gvrl-table-body {
  display: table-row-group;
}
.gvrl-table-row {
  display: table-row;
}
.gvrl-table-cell {
  display: table-cell;
}
@media (min-width: 1200px) {
  .container {
    width: 970px;
  }
}
</style>
