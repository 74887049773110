<template>
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding">
    <div class="block-wrapper-top">
      <div class="block-left"></div>
      <div class="block-right"></div>
      <div class="block-center"></div>
    </div>
    <div class="block-wrapper-left">
      <div class="block-wrapper-right">
        <div class="block-wrapper-center">
          <div class="block-wrapper-content twitter">
            <a ref="twitter" class="twitter-timeline" data-chrome="noheader nofooter noborders noscrollbar transparent" data-lang="uk" data-theme="dark" href="https://twitter.com/goverlaua?ref_src=twsrc%5Etfw">Tweets by goverlaua</a>
          </div>
        </div>
      </div>
    </div>
    <div class="block-wrapper-bottom">
      <div class="block-left"></div>
      <div class="block-right"></div>
      <div class="block-center"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Twitter",
  mounted() {
    let twitterWidget = document.createElement('script')
    twitterWidget.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    document.body.appendChild(twitterWidget);
  },
}
</script>

<style>
.twitter {
  height: 300px;
  overflow-y: scroll !important;
}
.twitter::-webkit-scrollbar {
  display: none !important;
}
</style>