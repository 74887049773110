<template>
  <div class="gvrl-table-cell bid">{{ absolute }}</div>
</template>

<script>
export default {
  name: "RatesValue",
  props: [
      "value",
      "exponent"
  ],
  computed: {
    absolute: function () {
      return (this.value.absolute / (10 ** (this.exponent))).toFixed(this.exponent);
    },
    relative: function () {
      return (this.value.relative / (10 ** (this.exponent))).toFixed(this.exponent);
    },
    updated_at: function () {
      let dt = new Date(this.value.updatedAt);
      return [dt.getDate(), dt.getMonth()+1, dt.getFullYear()]
          .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
    }
  }
}
</script>

<style scoped>

</style>