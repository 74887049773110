<template>
  <div class="gvrl-table-row">
    <ForexCurrencyPair :base="major.base" :quote="major.quote" />
    <ForexValue :value="major.bid" :precision="major.precision" />
    <ForexValue :value="major.ask" :precision="major.precision" />
  </div>
</template>

<script>
import ForexValue from "@/components/ForexValue";
import ForexCurrencyPair from "@/components/ForexCurrencyPair";

export default {
  name: 'ForexRow',
  props: ["major"],
  components: {
    ForexValue,
    ForexCurrencyPair
  }
}
</script>

<style scoped>

</style>