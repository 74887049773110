<template>
  <div class="gvrl-table-row">
    <RatesCurrency :currency="rate.currency" />
    <RatesValue :value="rate.bid" :exponent="rate.currency.exponent" />
    <RatesValue :value="rate.ask" :exponent="rate.currency.exponent" />
  </div>
</template>

<script>
import RatesValue from "@/components/RatesValue";
import RatesCurrency from "@/components/RatesCurrency";

export default {
  name: "RatesRow",
  props: ["rate"],
  components: {
    RatesCurrency,
    RatesValue
  }
}
</script>

<style scoped>

</style>