<template>
  <div class="gvrl-table-cell">
    <img :src="getFlagURL(currency.alias)" :alt="currency.codeAlpha" :title="currency.codeAlpha" />
  </div>
</template>

<script>
export default {
  name: "RatesCurrency",
  props: ["currency"],
  methods: {
    getFlagURL(alias) {
      return require('../assets/flags/'+alias+'.png')
    }
  }
}
</script>

<style scoped>

</style>